import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import { issueToken } from '../apiCalls';
import { useHistory } from 'react-router-dom';

const SignInSchema = Yup.object().shape({
  username: Yup.string().required('Field required'),
  password: Yup.string().required('Field required')
});

interface Values {
  username: string;
  password: string;
}

const AdminAuth = () => {
  const history = useHistory();

  const handleSubmit = async (values: Values) => {
    await issueToken(values);
    history.push('/admin');
  };

  return (
    <div className="bg-orange p-8 rounded-lg">
      <Formik
        initialValues={{
          username: '',
          password: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={SignInSchema}
      >
        {({ errors, touched }) => (
          <Form className="flex flex-col items-center space-y-4">
            <h1 className="text-2xl font-medium mb-4">Sign in</h1>
            <Field
              name="username"
              as={TextInput}
              label="Username"
              error={touched.username ? errors.username : null}
            />
            <Field
              name="password"
              as={TextInput}
              label="Password"
              type="password"
              error={touched.username ? errors.username : null}
            />
            <Button type="submit" fullWidth>
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdminAuth;
