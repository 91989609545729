import clsx from 'clsx';
import { textInputClasses } from '../TextInput/TextInput';

interface Props extends React.HTMLProps<HTMLSelectElement> {}

const Select = (props: Props) => {
  return <select className={clsx(textInputClasses, "max-w-full")} {...props} />;
};

export default Select;
