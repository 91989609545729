import { useEffect, useState } from 'react';
import apiClient from '../../services/apiClient';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

const AuthInterceptorProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [interceptorIn, setInterceptorIn] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const id = apiClient.interceptors.response.use(
      undefined,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          history.push('/admin/auth');
        }
      }
    );

    setInterceptorIn(true);

    return () => apiClient.interceptors.response.eject(id);
  }, [history]);

  if (!interceptorIn) return null;

  return <>{children}</>;
};

export default AuthInterceptorProvider;
