import Button from '../../../../components/Button';
import { Contract } from '@ethersproject/contracts';
import { config } from '../../../../utils/config';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import {
  CoinExchangeDirection,
  CoinExchangeStatus
} from '../../../../types/common';
import { useMemo } from 'react';

interface Props {
  paymentWallet: string;
  payoutWallet: string;
  amount: number;
  status: CoinExchangeStatus;
  swapDirection: CoinExchangeDirection;
}

const getTotalAmountFrom = (amount: number) => BigNumber.from(amount).mul(1e8);

const SwapsTableActionsCell = ({
  paymentWallet,
  payoutWallet,
  amount,
  status,
  swapDirection
}: Props) => {
  const { library } = useWeb3React<Web3Provider>();

  const { address, abi } = config.blockchain.smartContract;
  const contract = useMemo(
    () => new Contract(address, abi, library?.getSigner()),
    [library, address, abi]
  );

  const handleMintClick = async () => {
    await contract.mint(payoutWallet, getTotalAmountFrom(amount-5));
  };

  const handleBurnClick = async () => {
    await contract.burn(paymentWallet, getTotalAmountFrom(amount));
  };

  if (status !== CoinExchangeStatus.CoinsReceived) return null;

  if (swapDirection === CoinExchangeDirection.BtxToSbtx) {
    return <Button onClick={handleMintClick}>Mint</Button>;
  } else {
    return <Button onClick={handleBurnClick}>Burn</Button>;
  }
};

export default SwapsTableActionsCell;
