import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomeRoute from '../routes/HomeRoute';
import { QueryClient, QueryClientProvider } from 'react-query';
import AdminRoute from '../routes/AdminRoute';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import { ToastContainer } from 'react-toastify';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ToastContainer />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route exact path={['/', '/check/:swapId']} component={HomeRoute} />
            <Route path="/admin" component={AdminRoute} />
          </Switch>
        </QueryClientProvider>
      </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default App;
