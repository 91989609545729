import { WalletKind } from '../../../../types/common';
import React from 'react';

interface Props {
  address: string;
  kind: WalletKind;
}

const getHref = (walletAddress: string, walletKind: WalletKind) =>
  walletKind === WalletKind.Ethereum
    ? `https://bscscan.com/address/${encodeURIComponent(walletAddress)}`
    : `https://chainz.cryptoid.info/btx/address.dws?${encodeURIComponent(
        walletAddress
      )}`;

const SwapsTableAddressCell = ({ address, kind }: Props) => {
  return (
    <a
      href={getHref(address, kind)}
      className="break-all text-primary-light underline"
      target="_blank"
      rel="noreferrer"
    >
      {address}
    </a>
  );
};

export default SwapsTableAddressCell;
