import copyIcon from '../../assets/copy.svg';
import { useRef } from 'react';

interface Props {
  address: string;
  label: string;
}

const CheckSwapAddress = ({ address, label }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopyClick = () => {
    if (!inputRef.current) return;

    inputRef.current.select();
    document.execCommand('copy');
  };

  return (
    <div className="mt-4">
      <label>
        <span className="ml-4 block text-sm">{label}</span>
        <div className="flex">
          <input
            className="rounded-l-lg italic font-bold flex-grow px-2 py-1"
            value={address}
            readOnly
            ref={inputRef}
          />
          <button
            className="bg-orange rounded-r-lg px-2"
            aria-label="copy address to clipboard"
            onClick={handleCopyClick}
          >
            <img src={copyIcon} alt="" width={20} />
          </button>
        </div>
      </label>
    </div>
  );
};

export default CheckSwapAddress;
