import clsx from 'clsx';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  secondary?: boolean;
  small?: boolean;
}

const Button = ({ fullWidth, secondary, small, className, ...rest }: Props) => {
  const classes = clsx(
    [fullWidth && 'w-full'],
    'rounded-lg text-orange-button italic uppercase font-bold transition-colors px-4',
    'p-2',
    'bg-black hover:bg-opacity-90',
    className
  );

  return <button className={classes} {...rest} />;
};

export default Button;
