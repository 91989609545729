import batImg from '../../assets/bat.png';

const Divider = () => {
  return (
    <div className="w-full bg-black h-px relative">
      <img
        className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 transform w-1/5"
        src={batImg}
        alt=""
      />
    </div>
  );
};

export default Divider;
