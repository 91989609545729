import Button from '../Button';

interface Props {
  title: string;
  onConfirmClick(): void;
  onCancelClick(): void;
}

const ConfirmationDialog = ({
  title,
  onConfirmClick,
  onCancelClick,
  children
}: React.PropsWithChildren<Props>) => {
  return (
    <div className="fixed flex items-center justify-center inset-0 bg-black bg-opacity-50">
      <div className="rounded-lg overflow-hidden">
        <h2 className="bg-orange font-bold px-4 py-2 text-lg ">{title}</h2>
        <div className="bg-orange-lightest">
          <p className="px-4 py-2">{children}</p>
          <div className="flex justify-end p-2 space-x-2">
            <Button secondary small onClick={onCancelClick}>
              No
            </Button>
            <Button small onClick={onConfirmClick}>
              Yes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
