import { CoinExchangeStatus } from '../../types/common';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import checkboxIcon from '../../assets/checkbox.svg';
import refreshIcon from '../../assets/refresh.svg';
import checkboxUncheckedIcon from '../../assets/checkbox_unchecked.svg';

interface Props {
  status: CoinExchangeStatus;
}

const statusNames = {
  [CoinExchangeStatus.CoinsReceived]: 'Coins received',
  [CoinExchangeStatus.WaitingForCoins]: 'Exchange requested',
  [CoinExchangeStatus.Completed]: 'Coins paid out'
};

const CheckSwapStatus = ({ status }: Props) => {
  const getComponentForStatus = (s: CoinExchangeStatus) => {
    const curr = +status;

    let icon = checkboxUncheckedIcon;
    let spin = false;

    if (s <= curr) {
      icon = checkboxIcon;
    } else if (s === curr + 1) {
      icon = refreshIcon;
      spin = true;
    }

    return (
      <img
        src={icon}
        width={20}
        alt=""
        className={spin ? 'animate-spin' : ''}
      />
    );
  };

  let errorMessage;

  if (status === CoinExchangeStatus.PaymentAmountMismatch) {
    errorMessage = 'Error: Payment amount mismatch';
  } else if (status === CoinExchangeStatus.Cancelled) {
    errorMessage = 'Cancelled';
  }

  if (errorMessage) {
    return <p className="text-red my-2 text-center">{errorMessage}</p>;
  }

  return (
    <ul>
      {(
        Object.keys(statusNames) as unknown as (keyof typeof statusNames)[]
      ).map((k) => (
        <li className="my-2 flex items-center">
          <span className="mr-2">{getComponentForStatus(+k)}</span>
          {statusNames[k]}
        </li>
      ))}
    </ul>
  );
};

export default CheckSwapStatus;
