import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

const navItems = [
  {
    name: 'Swaps',
    path: '/admin'
  }
];

const AdminDashboardNav = () => {
  const { pathname } = useLocation();

  const getClasses = (path: string) =>
    clsx(
      [
        pathname === path && 'border-l-4 border-orange-light bg-orange-lightest'
      ],
      'block px-3 py-2'
    );

  return (
    <div className="w-72 h-screen bg-orange">
      <h1 className="text-2xl p-3">Dashboard</h1>
      <ul>
        {navItems.map((i) => (
          <li key={i.name}>
            <Link className={getClasses(i.path)} to={{ pathname: i.path }}>
              {i.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminDashboardNav;
