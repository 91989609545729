export interface CoinExchange {
  id: number;
  emailAddress: string;
  amount: number;
  paymentWallet: Wallet;
  payoutWallet: Wallet;
  editedOn: string;
  createdOn: string;
  status: CoinExchangeStatus;
  direction: CoinExchangeDirection;
}

export interface Wallet {
  address: string;
  kind: WalletKind;
}

export enum WalletKind {
  Bitcoin,
  Ethereum
}

export enum CoinExchangeStatus {
  WaitingForCoins,
  CoinsReceived,
  Completed,
  PaymentAmountMismatch,
  Cancelled
}

export interface PaginatedResult<T> {
  page: number;
  pagesCount: number;
  data: T[];
}

export enum CoinExchangeDirection {
  BtxToSbtx,
  SbtxToBtx
}
