import apiClient from '../../services/apiClient';
import { config } from '../../utils/config';
import {
  CoinExchange,
  CoinExchangeStatus,
  PaginatedResult
} from '../../types/common';

interface IssueTokenRequest {
  username: string;
  password: string;
}

interface SetCoinExchangeStatusRequest {
  newStatus: CoinExchangeStatus;
}

export const getCoinExchanges = async (
  page = 0,
  resultsPerPage = 10
): Promise<PaginatedResult<CoinExchange>> => {
  const { data } = await apiClient.get(config.api.endpoints.coinExchange, {
    params: { page, resultsPerPage }
  });

  return data;
};

export const setCoinExchangeStatus = async (
  exchangeId: number,
  request: SetCoinExchangeStatusRequest
) => {
  await apiClient.post(
    `${config.api.endpoints.coinExchange}/${exchangeId}/status`,
    request
  );
};

export const issueToken = async (request: IssueTokenRequest) => {
  await apiClient.post(config.api.endpoints.token, request);
};
