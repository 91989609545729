import metamask_fox from '../../assets/metamask-fox.svg';
import clsx from 'clsx';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useEffect } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { config } from '../../utils/config';
import { toast } from 'react-toastify';

interface Props {
  className?: string;
}

const AddToMetamaskButton = ({ className }: Props) => {
  const { activate, library, active } = useWeb3React<Web3Provider>();

  useEffect(() => {
    activate(
      new InjectedConnector({
        supportedChainIds: [config.blockchain.networkId]
      })
    );
  }, [activate]);

  const handleClick = async () => {
    if (!active) {
      toast.error(
        'Provider not active. Make sure you are connected to BNC main network'
      );
    }
    library?.provider?.request?.({
      method: 'wallet_watchAsset',
      params: {
        // @ts-ignore
        type: 'ERC20',
        options: {
          address: '0x000000089fb24237dA101020Ff8e2AfD14624687',
          symbol: 'sBTX',
          decimals: 8,
          image:
            'https://bitcore.cc/wp-content/uploads/2021/08/sBTXlogo512x512px.png'
        }
      }
    });
  };

  return (
    <button
      aria-label="Add to Metamask"
      className={clsx('absolute hover:opacity-80', className)}
      onClick={handleClick}
    >
      <div className="p-1 bg-white rounded-full">
        <img src={metamask_fox} alt="" height={24} width={24} />
      </div>
      <div className="right-0 absolute bg-white rounded-full -bottom-1/4 w-4 h-4 overflow-hidden border-2 border-orange">
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          +
        </div>
      </div>
    </button>
  );
};

export default AddToMetamaskButton;
