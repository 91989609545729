import CenteredPage from '../components/CenteredPage/CenteredPage';
import SwapPanel from '../features/swap/SwapPanel';
import { Route, Switch } from 'react-router-dom';
import CheckSwapPanel from '../features/check/CheckSwapPanel';
import CenteredContainer from '../components/CenteredContainer';

const HomeRoute = () => {
  return (
    <CenteredPage>
      <CenteredContainer>
        <Switch>
          <Route exact path="/" component={SwapPanel} />
          <Route path="/check/:swapId" component={CheckSwapPanel} />
        </Switch>
      </CenteredContainer>
    </CenteredPage>
  );
};

export default HomeRoute;
