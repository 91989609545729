import clsx from 'clsx';

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'label'> {
  label: string | JSX.Element;
  error?: string;
}

export const textInputClasses =
  'rounded-lg p-1 text-center font-bold text-black italic';

const TextInput = ({ label, error, ...rest }: Props) => {
  return (
    <label className="text-black text-center w-full block">
      {label}
      <input
        className={clsx('block rounded-lg w-full mt-1', textInputClasses)}
        {...rest}
      />
      <span className="text-sm text-red">{error}</span>
    </label>
  );
};

export default TextInput;
