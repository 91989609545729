import { CoinExchangeStatus } from '../../../../types/common';
import Select from '../../../../components/Select';
import { statusNames } from '../../../../utils/constants';

interface Props {
  coinExchangeId: number;
  currentStatus: CoinExchangeStatus;
  onStatusChange?(coinExchangeId: number, status: CoinExchangeStatus): void;
}

const SwapsTableStatusCell = ({
  coinExchangeId,
  currentStatus,
  onStatusChange
}: Props) => {
  const handleStatusChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newValue = +e.target.value;
    onStatusChange?.(coinExchangeId, newValue);
  };

  return (
    <Select value={currentStatus} onChange={handleStatusChange}>
      {(
        Object.keys(statusNames) as unknown as (keyof typeof statusNames)[]
      ).map((s) => (
        <option value={s} key={s}>
          {statusNames[s]}
        </option>
      ))}
    </Select>
  );
};

export default SwapsTableStatusCell;
