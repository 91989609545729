import AdminDashboardNav from './AdminDashboardNav';
import { Route, Switch } from 'react-router-dom';
import AdminDashboardSwaps from './AdminDashboardSwaps/AdminDashboardSwaps';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { config } from '../../utils/config';

const AdminDashboard = () => {
  const { activate } = useWeb3React();

  useEffect(() => {
    activate(
      new InjectedConnector({
        supportedChainIds: [config.blockchain.networkId]
      })
    );
  }, [activate]);

  return (
    <div className="flex bg-blue h-screen">
      <AdminDashboardNav />
      <div className="flex-grow p-3">
        <Switch>
          <Route exact path="/admin" component={AdminDashboardSwaps} />
        </Switch>
      </div>
    </div>
  );
};

export default AdminDashboard;
