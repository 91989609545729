import { Field, Form, Formik } from 'formik';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { useMutation } from 'react-query';
import { createCoinExchange } from './apiCalls';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useState } from 'react';
import { CoinExchangeDirection } from '../../types/common';

const schemaBaseElements = {
  emailAddress: Yup.string().email('Invalid email address'),
  amount: Yup.number().min(6).test("noDecimals", "Decimals not allowed", val => Number.isInteger(val)).required('Field required')
};

const BtxToSbtxSchema = Yup.object().shape({
  payoutWalletAddress: Yup.string()
    .matches(/0x[a-fA-F0-9]{40}/g, 'Invalid address')
    .required('Field required'),
  ...schemaBaseElements
});

const SbtxToBtxSchema = Yup.object().shape({
  payoutWalletAddress: Yup.string()
    .matches(/^[2][a-km-zA-HJ-NP-Z1-9]{25,34}$/g, 'Invalid address')
    .required('Field required'),
  ...schemaBaseElements
});

interface Values {
  payoutWalletAddress: string;
  emailAddress: string;
  amount: number;
}

const SwapForm = () => {
  const [reverseSwap, setReverseSwap] = useState(false);
  const [values, setValues] = useState<Values>();
  const history = useHistory();

  const createCoinExchangeMutation = useMutation(
    'createCoinExchange',
    createCoinExchange,
    {
      onSuccess: (data) => {
        history.push({
          pathname: `/check/${data.id}`,
          search: new URLSearchParams({
            payoutAddress: data.payoutWallet.address
          }).toString()
        });
      }
    }
  );

  const handleChangeSwapDirectionClick = () => {
    setReverseSwap((p) => !p);
  };

  const handleSubmit = (values: Values) => {
    setValues(values);
  };

  const handleConfirmClick = async () => {
    if (!values) {
      return;
    }

    await createCoinExchangeMutation.mutateAsync({
      ...values,
      direction: reverseSwap
        ? CoinExchangeDirection.SbtxToBtx
        : CoinExchangeDirection.BtxToSbtx
    });
  };

  const walletName = reverseSwap ? 'BTX' : 'BSC';

  return (
    <div className="w-full flex items-center flex-col bg-orange-light py-8 px-8">
      {values && (
        <ConfirmationDialog
          title="Do you really want to start this swap?"
          onConfirmClick={handleConfirmClick}
          onCancelClick={() => setValues(undefined)}
        >
          <p>Amount: {values?.amount} BTX</p>
          <p>Address: {values?.payoutWalletAddress}</p>
        </ConfirmationDialog>
      )}
      <Button className="mb-2" onClick={handleChangeSwapDirectionClick}>
        {reverseSwap ? 'SBTX to BTX' : 'BTX to SBTX'}
      </Button>
      <Formik
        initialValues={{
          payoutWalletAddress: '',
          emailAddress: '',
          amount: 10
        }}
        onSubmit={handleSubmit}
        validationSchema={reverseSwap ? SbtxToBtxSchema : BtxToSbtxSchema}
      >
        {({ errors, touched }) => (
          <Form className="w-full">
            <div className="space-y-6 w-full flex flex-col w-full mb-4">
              <Field
                name="amount"
                as={TextInput}
                label={
                  <>
                    Swap Amount in{' '}
                    <span className="font-bold">
                      {reverseSwap ? 'SBTX' : 'BTX'} <br/> 5 BTX Swap Fee
                    </span>
                  </>
                }
                error={touched.amount ? errors.amount : null}
                type="number"
              />
              <Field
                name="payoutWalletAddress"
                as={TextInput}
                error={
                  touched.payoutWalletAddress
                    ? errors.payoutWalletAddress
                    : null
                }
                label={`Your ${walletName} wallet`}
                placeholder={`${walletName} Address`}
              />
              <Field
                name="emailAddress"
                as={TextInput}
                error={touched.emailAddress ? errors.emailAddress : null}
                label="Email address"
                placeholder="example@example.com"
              />
              <Button>Start swap</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SwapForm;
