import brandImg from '../../assets/brand.png';
import Divider from '../../components/Divider';
import SwapForm from './SwapForm';
import CheckSwapForm from '../check/CheckSwapForm';
import AddToMetamaskButton from '../addToMetamask/AddToMetamaskButton';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

const SwapPanel = () => {
  return (
    <>
      <div className="px-8 pt-4 pb-6 relative">
        <Web3ReactProvider getLibrary={getLibrary}>
          <AddToMetamaskButton className="right-2 top-2" />
        </Web3ReactProvider>
        <img src={brandImg} alt="" />
        <h1 className="text-center mt-4 mb-2">
          Exchange <span className="font-bold">BTX</span> and{' '}
          <span className="font-bold">SBTX</span> (BEP-20)
        </h1>
      </div>
      <Divider />
      <SwapForm />
      <Divider />
      <CheckSwapForm />
    </>
  );
};

export default SwapPanel;
