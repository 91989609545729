import AdminDashboard from '../features/admin/AdminDashboard';
import AuthInterceptorProvider from '../features/auth/AuthInterceptorProvider';
import { Route, Switch } from 'react-router-dom';
import CenteredPage from '../components/CenteredPage/CenteredPage';
import AdminAuth from '../features/admin/AdminAuth/AdminAuth';

const AdminRoute = () => {
  return (
    <AuthInterceptorProvider>
      <Switch>
        <Route exact path="/admin/auth">
          <CenteredPage>
            <AdminAuth />
          </CenteredPage>
        </Route>
        <Route>
          <AdminDashboard />
        </Route>
      </Switch>
    </AuthInterceptorProvider>
  );
};

export default AdminRoute;
