import { Column, useFlexLayout, usePagination, useTable } from 'react-table';
import { CoinExchange, CoinExchangeStatus } from '../../../../types/common';
import React, { useEffect, useState } from 'react';
import SwapsTableStatusCell from './SwapsTableStatusCell';
import Button from '../../../../components/Button';
import SwapsTableAddressCell from './SwapsTableAddressCell';
import SwapsTableActionsCell from './SwapsTableActionsCell';
import { setCoinExchangeStatus } from '../../apiCalls';

interface Props {
  exchanges: CoinExchange[];
  pageCount: number;
  fetchData(page: number, resultsPerPage: number): Promise<any>;
}

const SwapsTable = ({ fetchData, exchanges, pageCount }: Props) => {
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const handleStatusChange = async (
    coinExchangeId: number,
    newStatus: CoinExchangeStatus
  ) => {
    await setCoinExchangeStatus(coinExchangeId, { newStatus });
    setRefreshTrigger((p) => !p);
  };

  const columns: Column<CoinExchange>[] = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 30
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        maxWidth: 100
      },
      {
        Header: 'Payment Wallet',
        accessor: 'paymentWallet',
        Cell: (props) => (
          <SwapsTableAddressCell
            address={props.value.address}
            kind={props.value.kind}
          />
        )
      },
      {
        Header: 'Payout Wallet',
        accessor: 'payoutWallet',
        Cell: (props) => (
          <SwapsTableAddressCell
            address={props.value.address}
            kind={props.value.kind}
          />
        )
      },
      {
        Header: 'Email Address',
        accessor: 'emailAddress'
      },
      {
        Header: 'Created',
        accessor: (c) => new Date(c.createdOn).toLocaleString(),
        maxWidth: 120
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => (
          <SwapsTableStatusCell
            coinExchangeId={props.row.cells[0].value}
            currentStatus={props.cell.value}
            onStatusChange={handleStatusChange}
          />
        ),
        minWidth: 150
      },
      {
        Header: 'Actions',
        Cell: (props: any) => (
          <SwapsTableActionsCell
            status={props.row.original.status}
            payoutWallet={props.row.original.payoutWallet.address}
            paymentWallet={props.row.original.paymentWallet.address}
            amount={props.row.original.amount}
            swapDirection={props.row.original.direction}
          />
        ),
        maxWidth: 80
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: exchanges,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount
    },
    usePagination,
    useFlexLayout
  );

  useEffect(() => {
    fetchData(pageIndex, pageSize);
  }, [fetchData, pageIndex, pageSize, refreshTrigger]);

  return (
    <div>
      <table className="w-full rounded-lg overflow-hidden" {...getTableProps()}>
        <thead className="bg-orange-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="px-4 py-2 text-left"
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                className="bg-orange-lightest hover:bg-opacity-50 transition-all"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td className="px-4 py-2" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-2 space-x-2">
        <Button small onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>
        <Button
          small
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button small onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>
        <Button
          small
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          className="rounded-lg py-3 px-6 text-center font-bold text-black ml-2"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SwapsTable;
