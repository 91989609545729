import swapImg from '../../assets/swap.svg';

interface Props {
  exchangeId: number;
  amount: number;
  isReverseSwap: boolean;
}

const CheckSwapPanelHeader = ({ exchangeId, amount, isReverseSwap }: Props) => {
  return (
    <div className="pt-4 pb-8 px-8">
      <div className="flex justify-center mb-4">
        <img width={120} src={swapImg} alt="" />
      </div>
      <div className="flex justify-around flex-wrap">
        <h1>
          Coin Exchange <span className="font-bold">#{exchangeId}</span>
        </h1>
        <p className="font-bold bg-orange-lightest rounded-full px-3 py-0.5">
          {amount} {isReverseSwap ? 'SBTX → BTX' : 'BTX → SBTX'}
        </p>
      </div>
    </div>
  );
};

export default CheckSwapPanelHeader;
