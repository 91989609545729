import apiClient from '../../services/apiClient';
import { config } from '../../utils/config';
import { CoinExchange, CoinExchangeDirection } from '../../types/common';

interface CreateCoinExchangeRequest {
  payoutWalletAddress: string;
  emailAddress: string;
  amount: number;
  direction: CoinExchangeDirection;
}

export const createCoinExchange = async (
  request: CreateCoinExchangeRequest
): Promise<CoinExchange> => {
  const { data } = await apiClient.post(
    config.api.endpoints.coinExchange,
    request
  );

  return data;
};

export const getCoinExchange = async (
  id: number,
  payoutAddress: string
): Promise<CoinExchange> => {
  const { data } = await apiClient.get(
    `${config.api.endpoints.coinExchange}/${id}`,
    {
      params: { payoutAddress }
    }
  );

  return data;
};

export const cancelCoinExchange = async (id: number, payoutAddress: string) => {
  await apiClient.post(`${config.api.endpoints.coinExchange}/${id}/cancel`, {
    params: { payoutAddress }
  });
};
