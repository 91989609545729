import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

const CheckSwapSchema = Yup.object().shape({
  swapId: Yup.number().required('Field required'),
  payoutAddress: Yup.string().required('Field required')
});

interface Values {
  swapId?: number;
  payoutAddress: string;
}

const CheckSwapForm = () => {
  const history = useHistory();

  const handleSubmit = (values: Values) => {
    history.push({
      pathname: `/check/${values.swapId}`,
      search: new URLSearchParams({
        payoutAddress: values.payoutAddress
      }).toString()
    });
  };

  return (
    <Formik
      initialValues={{
        payoutAddress: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={CheckSwapSchema}
    >
      {({ errors, touched }) => (
        <Form className="w-full flex items-center flex-col p-8 bg-orange-lightest">
          <div className="space-y-6 flex flex-col w-full">
            <Field
              as={TextInput}
              name="swapId"
              label="Swap ID"
              error={touched.swapId ? errors.swapId : null}
              type="number"
            />
            <Field
              as={TextInput}
              name="payoutAddress"
              label="Your wallet address"
              error={touched.payoutAddress ? errors.payoutAddress : null}
            />
            <Button type="submit">Check Swap</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CheckSwapForm;
