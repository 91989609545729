import React, { useCallback, useEffect, useState } from 'react';
import SwapsTable from './SwapsTable/SwapsTable';
import { getCoinExchanges } from '../apiCalls';
import { CoinExchange, PaginatedResult } from '../../../types/common';

const AdminDashboardSwaps = () => {
  const [data, setData] = useState<PaginatedResult<CoinExchange>>();

  const fetchData = useCallback(
    async (page?: number, resultsPerPage?: number) => {
      const newData = await getCoinExchanges(page, resultsPerPage);
      setData(newData);
    },
    []
  );

  useEffect(() => {
    fetchData(0, 10);
  }, [fetchData]);

  if (!data) {
    return <p>Loading</p>;
  }

  return (
    <div className="w-full bg-orange rounded-lg p-3 overflow-auto max-h-full">
      <SwapsTable
        fetchData={fetchData}
        exchanges={data?.data}
        pageCount={data?.pagesCount}
      />
    </div>
  );
};

export default AdminDashboardSwaps;
