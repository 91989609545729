import { useMutation, useQuery, useQueryClient } from 'react-query';
import { cancelCoinExchange, getCoinExchange } from '../swap/apiCalls';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import CheckSwapStatus from './CheckSwapStatus';
import CheckSwapAddress from './CheckSwapAddress';
import Button from '../../components/Button';
import { CoinExchangeDirection, CoinExchangeStatus } from '../../types/common';
import { useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CheckSwapPanelHeader from './CheckSwapPanelHeader';
import Divider from '../../components/Divider';

interface RouteParams {
  swapId: string;
}

const CheckSwapPanel = () => {
  const [cancelRequested, setCancelRequested] = useState(false);

  const swapId = +useParams<RouteParams>().swapId;
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const payoutAddress = urlSearchParams.get('payoutAddress');

  const { data, error } = useQuery(
    ['swap', swapId, payoutAddress],
    () => getCoinExchange(swapId, payoutAddress ?? ''),
    {
      refetchInterval: 2 * 60 * 1000
    }
  );

  const queryClient = useQueryClient();

  const cancelMutation = useMutation(
    'cancelSwap',
    () => cancelCoinExchange(swapId, payoutAddress ?? ''),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['swap', swapId, payoutAddress]);
      }
    }
  );

  const handleCancelClick = () => {
    setCancelRequested(true);
  };

  const handleCancelCancelClick = () => {
    setCancelRequested(false);
  };

  const handleCancelConfirmClick = () => {
    cancelMutation.mutate();
  };

  const history = useHistory();

  const handleNewSwapClick = () => {
    history.push('/');
  };

  if ((error as AxiosError)?.response?.status === 404) return <p>Not found</p>;
  if (!data) return <p>Loading...</p>;

  return (
    <div className="w-full">
      <CheckSwapPanelHeader
        exchangeId={data.id}
        amount={data.amount}
        isReverseSwap={data.direction === CoinExchangeDirection.SbtxToBtx}
      />
      <Divider />
      <div className="px-8 pt-4 pb-8 bg-orange-light">
        <CheckSwapStatus status={data.status} />
        <CheckSwapAddress
          label="Payment address"
          address={data.paymentWallet.address}
        />
        <CheckSwapAddress
          label="Payout address"
          address={data.payoutWallet.address}
        />
      </div>
      <Divider />
      <div className="px-8 pt-8 pb-4 bg-orange-lightest">
        {data.status !== CoinExchangeStatus.Cancelled &&
          data.status !== CoinExchangeStatus.Completed && (
            <>
              {cancelRequested && (
                <ConfirmationDialog
                  title="Do you really want to cancel this swap?"
                  onConfirmClick={handleCancelConfirmClick}
                  onCancelClick={handleCancelCancelClick}
                >
                  This action cannot be undone
                </ConfirmationDialog>
              )}
              <Button fullWidth small onClick={handleCancelClick}>
                Cancel
              </Button>
            </>
          )}

        <Button
          secondary
          className="mt-2"
          fullWidth
          small
          onClick={handleNewSwapClick}
        >
          New swap
        </Button>
      </div>
    </div>
  );
};

export default CheckSwapPanel;
